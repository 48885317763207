import React, { useEffect, useState } from 'react';
import cascading from '../assests/css/headerstyle.module.css';
import { Link, useLocation } from 'react-router-dom';
import { Button, Container, Nav, NavDropdown, Navbar, Offcanvas } from 'react-bootstrap';
import logo from '../assests/images/logominis.png';
import { NavLink } from 'react-router-dom';
import { removeWallteAddress, walletControl } from '../lib/localStorage';
import { useDispatch, useSelector } from 'react-redux';
import Yourwallet from '../modals/yourwallet';
import { WALLET_ADDRESS } from '../constants'
import { CHAINS, FRONT_URL, ANALYTICS_URL } from '../config/env';
import walletimg from '../assests/images/wallet.png';
import { FaChevronDown } from 'react-icons/fa6';
import Walletmodal from '../modals/walletmodal';
import Networkmodal from '../modals/networkmodal';
import { FaAngleDown } from 'react-icons/fa';
export default function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [netmodal, setNetmodal] = useState(false)
  const [showmodal, setShowmodal] = useState(false)
  const [showmymodal, setShowmymodal] = useState(false)
  const [tabmodal, setTabmodal] = useState(false)
  const userdetail = useSelector((state) => state.admin)
  console.log("userdetail_userdetail", userdetail)

  const { networkSwitch } = useSelector((state) => state.networkSwitching)
  console.log("networkSwitching", networkSwitch)

  const walletdetail = useSelector((state) => state.wallet)
  console.log("walletdetail", walletdetail)

  const dispatch = useDispatch()
  const location = useLocation();

  const walletdis = () => {
    dispatch(walletControl('disconnect'))
    dispatch({
      type: WALLET_ADDRESS,
      payload: ''
    })
    removeWallteAddress()
    sessionStorage.removeItem('wallet')
    sessionStorage.setItem('walletStatus', 'disconnect')
  }
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <header className={`${cascading.header}`}>
        <Navbar className={isScrolled ? `${cascading.navbar} ${cascading.navbaractive}` : `${cascading.navbar}`} key="xl" expand="xl" >
          <Container className='customcontainer'>
            <Navbar.Brand href="/"><img src={logo} className={`img-fluid ${cascading.logoimg}`} alt='images' /></Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xl`} className={`${cascading.toggler} `} onClick={() => setMenuOpen(!menuOpen)} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-lg`}
              aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
              placement="end"
              restoreFocus={false}
              className={`${cascading.offcanvas} `}
              show={menuOpen}
              onHide={() => setMenuOpen(!menuOpen)}
            >
              <Offcanvas.Header closeButton >

              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className={`ms-xl-auto me-xl-auto align-items-xl-center ${cascading.navlist}`}>
                  <NavLink to="/">Home </NavLink>
                  <div className={`${cascading.dropdown}`} >
                    <button className={`${cascading.dropbtn} ${cascading.dropbtnactive}`} >HBLU <FaChevronDown fontSize={8} /></button>
                    <div className={`${cascading.dropcontent}`}>
                      <Link to="/swap">Swap</Link>
                      <Link to="/liquidity">Liquidity</Link>
                    </div>
                  </div>
                  <NavLink to="https://honeybluelockedstaging.maticz.in/staking">Locked staking</NavLink>
                  <NavLink to={`https://honeyblue.maticz.in/games`}>Game</NavLink>
                  <NavLink to="#">Faq</NavLink>



                </Nav>
                <div className={`${cascading.rightside}`}>
                  {/* <div>
                <IoMdSettings className={`${cascading.settingicon}`} fill='#fff' />
              </div> */}
                  <div className={`${cascading.networksec}`}>
                    <button className={`${cascading.networkbtn}`} onClick={() => (setNetmodal(true),setMenuOpen(false))} >
                      {networkSwitch && networkSwitch != '' &&
                        <>
                          <img src={CHAINS[networkSwitch]?.IMAGE} alt='' className={`${cascading.netimg}`} />
                          {console.log("NAME", CHAINS[networkSwitch]?.NAME, networkSwitch, CHAINS[networkSwitch])}
                          <p className={`d-none d-lg-block mb-0`}>{CHAINS[networkSwitch]?.NAME}</p>
                        </>
                      }
                    </button>
                  </div>
                  <div>

                    {location.pathname == "/" ?
                      <>
                        <Link to="/swap" className={`btn ${cascading.connectwalletbtn}`} type='button' >Enter App</Link>
                      </>
                      :
                      <>
                        {userdetail && userdetail?.walletStatus == 'connect' ?
                          <div className={`${cascading.headdrop}`}>
                            <div className={`${cascading.btnsec}`}>
                              {console.log("wallet", userdetail?.walletStatus == 'connect')}

                              <svg viewBox="0 0 24 24" color="primary" width="24px" xmlns="http://www.w3.org/2000/svg" class="sc-bdnxRM yzynT"><path fill-rule="evenodd" clip-rule="evenodd" d="M17 4C18.5 4 19 4.5 19 6L19 8C20.1046 8 21 8.89543 21 10L21 17C21 19 20 20 17.999 20H6C4 20 3 19 3 17L3 7C3 5.5 4.5 4 6 4L17 4ZM5 7C5 6.44772 5.44772 6 6 6L19 6L19 8L6 8C5.44772 8 5 7.55229 5 7ZM17 16C18 16 19.001 15 19 14C18.999 13 18 12 17 12C16 12 15 13 15 14C15 15 16 16 17 16Z"></path></svg>
                              <button className={`btn ${cascading.dropbtns}`} ><span className={`${cascading.dropbtnstext}`}>
                                {`${(walletdetail?.walletaddress).substring(0, 5)}...${walletdetail?.walletaddress.substring(38, 42)}`}
                              </span><FaAngleDown fill='#fff' className={`${cascading.arrowicon}`} /></button>
                            </div>

                            <div className={`${cascading.dropcontentstyle}`}>
                              <ul>
                                <li onClick={() => { setShowmymodal(true); setTabmodal(true) }}>Wallet</li>
                                <li onClick={() => { setShowmymodal(true); setTabmodal(false) }}>Transactions</li>
                                <li onClick={() => walletdis()}>Disconnect <svg viewBox="0 0 24 24" fill='#fff' color="text" width="20px" xmlns="http://www.w3.org/2000/svg" class="sc-bdnxRM bNbPUR"><path d="M16.3 8.09014C15.91 8.48014 15.91 9.10014 16.3 9.49014L18.2 11.3901H9C8.45 11.3901 8 11.8401 8 12.3901C8 12.9401 8.45 13.3901 9 13.3901H18.2L16.3 15.2901C15.91 15.6801 15.91 16.3001 16.3 16.6901C16.69 17.0801 17.31 17.0801 17.7 16.6901L21.29 13.1001C21.68 12.7101 21.68 12.0801 21.29 11.6901L17.7 8.09014C17.31 7.70014 16.69 7.70014 16.3 8.09014ZM4 19.3901H11C11.55 19.3901 12 19.8401 12 20.3901C12 20.9401 11.55 21.3901 11 21.3901H4C2.9 21.3901 2 20.4901 2 19.3901V5.39014C2 4.29014 2.9 3.39014 4 3.39014H11C11.55 3.39014 12 3.84014 12 4.39014C12 4.94014 11.55 5.39014 11 5.39014H4V19.3901Z"></path></svg></li>
                              </ul>

                            </div>
                          </div> :
                          <button className={`btn ${cascading.connectwalletbtn}`} type='button' onClick={() => setShowmodal(true)}><img className="wimg me-2" src={walletimg} alt="images" /> Connect Wallet</button>
                        }
                      </>
                    }
                  </div>

                </div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </header>
      {showmodal && <Walletmodal show={showmodal} onHide={() => setShowmodal(false)} />}
      {netmodal && <Networkmodal show={netmodal} onHide={() => setNetmodal(false)} />}
      {showmymodal && <Yourwallet show={showmymodal} statedata={tabmodal} onHide={() => setShowmymodal(false)} />}
    </>
  )
}
