import React, { useEffect, useState } from 'react'
import cascading from '../assests/css/footerstyle.module.css';

import Logo from '../assests/images/logoimg.png';
import { Link } from 'react-router-dom';


import { CHAINS, FRONT_URL, ANALYTICS_URL } from '../config/env';
import { getTokenPricesFromFarm } from '../hooks/usePools';
import { getNativeAddress } from '../hooks/useNativeToken';
import { roundToSignificant } from '../hooks/useCommon';
import facebook from '../assests/images/Frame (1).png';
import telegram from '../assests/images/Frame (2).png';
import discard from '../assests/images/Frame (3).png';
import mirror from '../assests/images/Frame (4).png';
import git from '../assests/images/Frame (5).png';
import { useSelector } from 'react-redux';

export default function Footer() {
  const { totalTvl } = useSelector((state) => (state.nftpool))
  const [nativePrice, SetnativePrice] = useState(0)
  useEffect(() => {
    NativePrice()
  }, [])
  const NativePrice = async () => {
    try {
      let farms = JSON.parse(localStorage.getItem("Farms"))
      let price = await getTokenPricesFromFarm(farms)
      let nativeAddress = getNativeAddress()
      let dspinusd = price[nativeAddress.toLowerCase()]
      SetnativePrice(dspinusd)
    } catch (err) {
      console.log(err, 'NativePrice__Err')
    }
  }
  return (
    <>
      <div className={`${cascading.footersec}`}>
        <div className={`container`}>
          <div className={`row`}>
            <div className={`col-lg-4 mb-3`}>
            <Link to="/"><img src={Logo} className={`img-fluid mb-3`} alt="image" /></Link>
            <p className={`${cascading.footdesc}`}>Lorem Ipsum is simply dummy text of the printing.</p>
            </div>
            <div className={`col-lg-4  mb-3`}>
            <ul>
                            <h6 className={`${cascading.headmenu}`}>Navigate</h6>
                            <li><Link to="https://blueiceegiant.maticz.in/">Home</Link></li>
                            <li><Link to="https://honeybluedex.maticz.in/swap">Swap</Link></li>
                            <li><Link to="https://honeybluedex.maticz.in/farms">Locked Staking</Link></li>
                            {/* <li><Link to="/earn">Earn</Link></li>
                            <li><Link to="/referral">Referral</Link></li> */}
                        </ul>
            </div>
            <div className={`col-lg-4 mb-3`}>
            <h6 className={`${cascading.headmenu}`}>Social</h6>
                        <div className={`${cascading.socialsec}`}>
                            <Link to="https://twitter.com/" target="_blank" ><img className={`img-fluid`} src={facebook} alt="pig" /></Link>
                            <Link to="https://telegram.org/" target="_blank"><img className={`img-fluid`} src={telegram} alt="pig" /></Link>
                            <Link to="https://discord.com/" target="_blank"><img className={`img-fluid`} src={discard} alt="pig" /></Link>
                            <Link to="/" target="_blank"><img className={`img-fluid`} src={mirror} alt="pig" /></Link>
                            <Link to="https://github.com/" target="_blank"><img className={`img-fluid`} src={git} alt="pig" /></Link>
                        </div>
            </div>

          </div>



        </div>

      </div>



    </>
  )
}
