import React, { useState, useEffect, useRef } from "react";
import tablecoin from '../assests/images/tablecoin.png';
import cascading from '../assests/css/offswap.module.css'

import visas from '../assests/images/visas.png';
import gpay from '../assests/images/gpays.png';
import { GiStarShuriken } from "react-icons/gi";


export default function Offswapcomp(props) {

    const bigComp = () => {
        return (
            <div className={`${cascading.offswapcomp}  mb-3`}>
                <div className={`d-flex justfun align-items-center justify-content-between flex-wrap`}>
                    <div className={`${cascading.interborder}`}>
                        <p className="mb-0"><img src={tablecoin} className="ethimg etimg me-1" alt="eth" />  WETH <span className="btop"></span></p>
                    </div>
                    <div className="text-end mt-3 mt-sm-0">
                        <h5 className={`${cascading.headlabel}`}>You Pay</h5>
                        <input type="text" className={`form-control ${cascading.styleinput}`} placeholder="0" />
                    </div>
                </div>
                <div className="mt-4 offpay">
                    <div className="d-flex gap-3 mb-4">
                        {/* <p className="dots mb-0"></p> */}
                        <GiStarShuriken className="stars" />
                        <div>
                            <h5 className={`${cascading.headlabel}`}>Using Payment Method</h5>
                            <div className="payment_det d-flex align-items-center gap-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        <img src={visas} className="img-fluid" />
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        <img src={gpay} className="img-fluid" />
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="d-flex gap-3">
                        <GiStarShuriken className="stars" />
                        <div>
                            <h5 className={`${cascading.headlabel}`}>See Fees Calculation</h5>
                            <h5 className={`${cascading.minihead} mt-3`}>125.21 <span className="grayclrs">Total Fees</span></h5>
                            <h5 className={`${cascading.minihead} mt-3`}>321 EUR = <span className="grayclrs">1 WETH</span></h5>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    const smallComp = () => {
        return (
            <div className={`${cascading.offswapcomp} mb-3`}offswapcomp  >
                <div className="d-flex justfun align-items-center justify-content-between flex-wrap">
                    <div className={`${cascading.interborder}`}>
                        <p className="mb-0"><img src={tablecoin} className="ethimg etimg me-1" alt="eth" />  WETH <span className="btop"></span></p>
                    </div>
                    <div className="text-end mt-3 mt-sm-0">
                        <h5 className={`${cascading.headlabel}`}>You Receive (Estimate)</h5>
                        <input type="text" className={`form-control ${cascading.styleinput}`}  placeholder="0" />
                    </div>
                </div>
            </div>
        )
    }
    console.log("props.offswapselect", props.offswapselect);
    return (
        <>
            <div>
                {props.offswapselect
                    ?
                    <>
                        {bigComp()}
                        {smallComp()}
                    </>
                    :
                    <>
                        {smallComp()}
                        {bigComp()}
                    </>}

                <div className="mt-4"><button type="button" className={`btn ${cascading.buybtn}`}>BUY NOW</button></div>


            </div>


        </>

    );
}