import React, { useEffect, useState } from 'react';
import cascading from '../assests/css/all.module.css';
import Footer from '../common/footer'
import Dashcard from '../components/dashcard';
import Approvecard from '../components/approvecard';
import Vestingcard from "../components/vestingcard";
import Balancecard from '../components/balancecard';
import Header from '../common/header';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

//lib 
import { consolelog } from '../lib/consolelog';
import { getWalletAddress } from '../lib/localStorage'
import { toFixedNumber } from '../lib/toFixedOf'

//hooks
import { getXtokenusage } from '../hooks/useXtoken';
import { isEmpty } from '../lib/isEmpty';
import { roundToSignificant } from '../hooks/useCommon';

export default function Dashboard() {
    const navigate = useNavigate()
    const { xtokenbalance } = useSelector((state) => (state.xtoken))
    const { allocationDetails } = useSelector((state) => (state.yield))
    const {dividendsdetails} =  useSelector((state)=>(state.dividends))
    const {launchallocations} =  useSelector((state)=>(state.launch))
    const { redeemDetails } = useSelector((state) => state.xtoken)
    console.log(allocationDetails,dividendsdetails,launchallocations,'Dashboard')
    //selector 
    const { walletaddress } = useSelector((state) => state.wallet)
    const [detailcard, setDetailcard] = useState([
        {
            label: "TOTAL YUM",
            value: "0",
            img: require("../assests/images/folder.png"),
            key: 'total'

        },
        {
            label: "AVAILABLE YUM",
            value: "0",
            img: require("../assests/images/unlock.png"),
            key: 'available'

        },
        {
            label: "ALLOCATED YUM",
            value: "0",
            img: require("../assests/images/lock.png"),
            key: 'allocate'

        },
        {
            label: "REDEEMING YUM",
            value: "0",
            img: require("../assests/images/timer.png"),
            key: 'redeem'

        }
    ])
    const [dashdetail, setDashdetail] = useState([
        {
            name: "Dividends",
            desc: "Earn real yield from protocol earnings by staking your YUM here.",
            img: require("../assests/images/divident.png"),
            mybal: "0",
            total: "11,468",
            fees: "0.5",
            path: "/dividends"

        },
        {
            name: "Yield booster",
            desc: "Boost your staking yields by up to +100% by adding YUM to any eligible position.",
            img: require("../assests/images/booster.png"),
            mybal: "0",
            total: "427",
            fees: "0.5",
            path: "/yieldbooster"

        },
        {
            name: "Launchpad",
            desc: "Get perks and benefits from every project on HBLU's launchpad by staking your YUM here.",
            img: require("../assests/images/launchpad.png"),
            mybal: "0",
            total: "376",
            fees: "0.5",
            path: "/launchpad"

        },
    ])


    //useEffect
    useEffect(() => {
        fetchDatas()
    }, [walletaddress,xtokenbalance])

    //get function
    const fetchDatas = async () => {
        try {

            if (getWalletAddress()) {
                let XtokenTotal = await getXtokenusage(getWalletAddress())
                console.log(XtokenTotal, 'XtokenTotal')
                let CardDetail = [...detailcard]
                for (let i = 0; i < CardDetail.length; i++) {
                    if (CardDetail[i].key == 'total') {
                        CardDetail[i].value = roundToSignificant(parseFloat(XtokenTotal.allocatedAmount) + parseFloat(XtokenTotal.redeemingAmount) + parseFloat(XtokenTotal.balance),6)
                    }
                    if (CardDetail[i].key == 'available') {
                        CardDetail[i].value = roundToSignificant(parseFloat(XtokenTotal.balance),6)
                    }
                    if (CardDetail[i].key == 'allocate') {
                        CardDetail[i].value = roundToSignificant(parseFloat(XtokenTotal.allocatedAmount),6)
                    }
                    if (CardDetail[i].key == 'redeem') {
                        CardDetail[i].value = roundToSignificant(parseFloat(XtokenTotal.redeemingAmount),6)
                    }
                    if (i == CardDetail.length - 1) {
                        console.log(CardDetail, 'CardDetail')
                        setDetailcard(CardDetail)
                    }
                }
                consolelog('XtokenTotal', XtokenTotal, true)
            }else{
                let CardDetail = [...detailcard]
                for (let i = 0; i < CardDetail.length; i++) {
                    if (CardDetail[i].key == 'total') {
                        CardDetail[i].value = 0
                    }
                    if (CardDetail[i].key == 'available') {
                        CardDetail[i].value = 0
                    }
                    if (CardDetail[i].key == 'allocate') {
                        CardDetail[i].value = 0
                    }
                    if (CardDetail[i].key == 'redeem') {
                        CardDetail[i].value = 0
                    }
                    if (i == CardDetail.length - 1) {
                        console.log(CardDetail, 'CardDetail')
                        setDetailcard(CardDetail)
                    }
                }
            }
        } catch (err) {
            consolelog('fetchDatas__err', err, true)
        }

    }

    useEffect(()=>{
        SetDashDetails()
    },[dividendsdetails,allocationDetails,launchallocations])

    const SetDashDetails = ()=>{
        try{
            let DashDetails = [...dashdetail]
            console.log(dividendsdetails,allocationDetails,launchallocations,'SetDashDetails')
            for(let i=0;i<DashDetails.length;i++){
                if(DashDetails[i].name == 'Dividends' ){
                    DashDetails[i].mybal =isEmpty(dividendsdetails?.usersAllocation) ? 0: `${roundToSignificant(dividendsdetails?.usersAllocation,6)}`
                    DashDetails[i].total = isEmpty(dividendsdetails?.totalAllocation) ? 0:`${roundToSignificant(dividendsdetails?.totalAllocation,6)}`
                    DashDetails[i].fees = isEmpty(dividendsdetails?.deallocationFee) ? 0:`${dividendsdetails?.deallocationFee}%`
                }
                if(DashDetails[i].name == 'Yield booster' ){
                    DashDetails[i].mybal =isEmpty(allocationDetails?.UserTotalAllocation) ? 0: `${roundToSignificant(allocationDetails?.UserTotalAllocation,6)}`
                    DashDetails[i].total = isEmpty(allocationDetails?.totalAllocation) ? 0: `${roundToSignificant(allocationDetails?.totalAllocation,6)}`
                    DashDetails[i].fees = isEmpty(allocationDetails?.deallocationFee) ? 0:`${allocationDetails?.deallocationFee}%`
                }
                if(DashDetails[i].name == 'Launchpad' ){
                    DashDetails[i].mybal =isEmpty(launchallocations?.userAllocation) ? 0: `${roundToSignificant(launchallocations?.userAllocation,6)}`
                    DashDetails[i].total = isEmpty(launchallocations?.totalAllocation) ? 0: `${roundToSignificant(launchallocations?.totalAllocation,6)}`
                    DashDetails[i].fees = isEmpty(launchallocations?.deallocateFee) ? 0: `${launchallocations?.deallocateFee}%`

                }
                if(i == DashDetails.length -1){
                    setDashdetail(DashDetails)
                }
            }
        }catch(err){
            console.log(err,'SetDashDetails__err')
        }
    }
    return (
        <>
            <Header />
            <div className={`${cascading.innerpages} ${cascading.yumpage}`}>
                <div className={`container`}>
                    <div className={`row ${cascading.headrow}`}>
                        <p className={`${cascading.pagehead}`}>Dashboard</p>
                        <p className={`${cascading.pagedesc}`}>Convert your DSP, redeem your YUM and manage your YUM plugins allocations.</p>
                    </div>
                    <div className={`row ${cascading.balancesec}`}>
                        {detailcard && detailcard.map((e) => {
                            console.log(e,'detailcard')
                            return (
                                <div className={`col-lg-3 mb-4`} >
                                    <Balancecard datas={e} />
                                </div>
                            )
                        })}


                    </div>
                    <div className={`row`}>
                        <div className={`col-lg-5 col-xl-4`}>
                            <Approvecard />
                            {redeemDetails.length > 0 ? <Vestingcard />:""}
                            {/* <Vestingcard /> */}
                        </div>
                        <div className={`offset-xl-1 col-lg-7 col-xl-7`}>
                            {dashdetail.map((e) => (
                                <Dashcard datas={e} navi={() => navigate(`${e.path}`)} />
                            ))}

                        </div>

                    </div>

                </div>


            </div>



            <Footer />
        </>
    )
}
